import "./Files.css"
import {createRef, useEffect, useState} from "react";
import {listAll, ref} from "firebase/storage"
import {auth, storage} from "../../../config/firebase";
import {File} from "./files/File"
import {FileState} from "./files/FileState";
import {FileDropWindow} from "./filedrop/FileDropWindow";

export const Files = (att) => {
	const [files, setFiles] = att.files
	const [filesBeforeUpload, setFilesBeforeUpload] = att.filesBeforeUpload
	const [filesInArgs, setFilesInArgs] = att.filesInArgs

	const [updateRunning, setUpdateRunning] = att.updateRunning
	const [onDrag, setOnDrag] = att.onDragState

	const fileInputRef = createRef()

	const rootRef = ref(storage, "/files/" + auth.currentUser.uid)

	const browse = function () {
		fileInputRef?.current?.click()
	}

	const uploadSetFiles = async function () {
		uploadFiles(filesBeforeUpload)
		setFilesBeforeUpload([])
	}

	const uploadFiles = async function(list) {
		for(let f of list) {
			const fileRef = ref(storage, `/files/${auth.currentUser.uid}/${f.name}`)
			setFiles(prevFiles => {
				let filter = Array.from(prevFiles.keys()).filter(state => state.storageRef._location.path_ === fileRef._location.path_)
				if(filter.length > 0) {
					filter[0].updateFile(f)
				} else {
					const state = new FileState(fileRef, [files, setFiles])
					state.updateFile(f)
					state.name = f.name
					state.onUpload = true
					prevFiles.set(state, <File key={fileRef._location.path_} files={[prevFiles, setFiles]} state={state}/>)
				}
				return prevFiles
			})
		}
	}

	const updateFiles = async function (id){
		try {
			let res = await listAll(rootRef)
			setFiles(prevFiles => {
				let items = new Map()
				let presentOnServer = []

				for (const storageRef of res.items) {
					if(Array.from(prevFiles.keys()).filter(state => state.storageRef._location.path_ === storageRef._location.path_).length === 0) {
						const state = new FileState(storageRef, [files, setFiles])
						prevFiles.set(state, <File key={storageRef._location.path_} files={[prevFiles, setFiles]} state={state}/>)
					}

					presentOnServer.push(storageRef._location.path_)
				}

				prevFiles.forEach((v, k) => {
					if(presentOnServer.includes(k.storageRef._location.path_) || k.onUpload) {
						items.set(k, v)
					}
				})

				items.forEach((v, k) => {
					k.downloadMeta()
				})

				return items
			})
		} catch (err) {
			if(id !== undefined) {
				clearInterval(id)
				setUpdateRunning(false)
			}
		}
	}

	//Setup 10s update interval
	useEffect(() => {
		updateFiles(-1)
		setUpdateRunning(prevUpdateRunning => {
			if(!prevUpdateRunning) {
				const id = setInterval(function () {
					updateFiles(id)
				}, 20000)
				return true
			} else {
				return false
			}
		})
	}, []);

	//Setup listener for electron file transfers
	useEffect(() => {
		setFilesInArgs(prevFilesInArgs => {
			if(prevFilesInArgs.length > 0) {
				uploadFiles(prevFilesInArgs)
				return []
			} else {
				return prevFilesInArgs
			}
		})
	}, [filesInArgs])

	return (
		<div className="Files">
			<div className="file_input_wrapper">
				<input ref={fileInputRef} id="file_input" name="file" type="file" onChange={(e) => setFilesBeforeUpload(e.target.files)} multiple/>

				<button className="special-btn" onClick={browse}>Browse</button>
				<button className="special-btn" onClick={uploadSetFiles}>Upload</button>

				<p className="file_input_display">{filesBeforeUpload.length > 0 ? (filesBeforeUpload.length > 1 ? `${filesBeforeUpload.length} files selected` : `Selected ${filesBeforeUpload[0].name}`) : ""}</p>
			</div>

			<FileDropWindow filesBeforeUpload={[filesBeforeUpload, setFilesBeforeUpload]} uploadFiles={uploadFiles} onDragState={att.onDragState}/>

			{Array.from(files.values())}
		</div>
	)
}
