import {auth} from "../config/firebase";
import {useNavigate} from "react-router-dom";
import {getCookie, validateSessionCookie} from "./generic/api/request";

export const IndexNavigator = () => {
    const navigate = useNavigate()

    const unsub = auth.onAuthStateChanged((user) => {
        if (user && getCookie("csrf_token")) {
            navigate("/dashboard", { replace: true })
            unsub()
        } else {
            navigate("/login", { replace: true })
            unsub()
        }
    })

    return (
        <div>

        </div>
    )
}
