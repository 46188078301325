import "./Navigator.css"
import {doc, updateDoc} from "firebase/firestore";
import {auth, db} from "../../../config/firebase";

export const Nav = (state) => {
	const [userState, setUserState] = state.state

	const changeDisplayState = function (e) {
		setUserState({update: true, state: {displayState: e.target.textContent}})
	}

	return (
		<div className="Nav">
			<div className="dropdown_container">
				<button id="home_button" className={userState.displayState === "Home" ? " active" : ""} onClick={changeDisplayState}>Home</button>
			</div>
			<button id="files_button" className={userState.displayState === "Files" ? "active" : ""} onClick={changeDisplayState}>Files</button>
			<button id="account_button" className={userState.displayState === "Account" ? "active" : ""} onClick={changeDisplayState}>Account</button>
		</div>
	)
}
