import "./Reset.css"
import "../../../auth/Auth.css"
import {LoginButton} from "../../../generic/buttons/LoginButton";
import {useEffect, useRef, useState} from "react";
import {SpecialButton} from "../../../generic/buttons/SpecialButton";
import {getCookie, post, validateSessionCookie} from "../../../generic/api/request";
import {auth} from "../../../../config/firebase";
import {useNavigate} from "react-router-dom";

export const Reset = (state) => {
    const navigate = useNavigate()
    const [password, setPassword] = useState("")
    const [passwordRepeat, setPasswordRepeat] = useState("")

    const [error, setError] = useState("")

    const responseRef = useRef()

    useEffect(() => {
        if(!auth?.currentUser?.email || !getCookie("csrf_token")) {
            navigate("/", { replace: true })
        }
    }, []);

    const resetPassword = async (e) => {
        const result = await post("/login/reset", {
            idToken: await auth.currentUser.getIdToken(),
            password: password,
            password_repeat: passwordRepeat,
        })

        const json = await result.json()

        if(json.success) {
            navigate("/")
            return;
        }

        if (json.error_type == 1) {
            setError("Failed: The Passwords did not match!")
        } else if(json.error_type == 2) {
            setError("Failed: Unknown Error!")
        }

        if(responseRef.current.classList.contains("fade")) {
            responseRef.current.classList.remove("fade")
        }

        await setTimeout(() => {
            responseRef.current.classList.add("fade")
        }, 10)
    }

    return (
        <div className="Reset">
            <div className="Auth">
                <p className="info" align="center">Reset Password</p>

                <input
                    align="center"
                    placeholder="Password"
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                />

                <input
                    align="center"
                    placeholder="Repeat Password"
                    type="password"
                    onChange={(e) => setPasswordRepeat(e.target.value)}
                />

                <div className="button_container">
                    <LoginButton click={resetPassword}>Continue</LoginButton>
                </div>
                <p ref={responseRef} className="response" align="center">{error}</p>
            </div>
        </div>
    )
}
