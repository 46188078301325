import "./CopyButton.css"
import copyButtonNormal from "../../../images/copy_button_normal.png"
import copyButtonActive from "../../../images/copy_button_active.png"
import {useState} from "react";

export const CopyButton = (e) => {
    const copyAction = e.onClick
    const [hasCopied, setHasCopied] = useState(false)

    const copy = () => {
        setHasCopied(true)

        copyAction()

        setTimeout(() => {
            setHasCopied(false)
        }, 2000)
    }


    if(hasCopied) {
        return (
            <img src={copyButtonActive} className="CopyButton active" title="Copied!"/>
        )
    } else {
        return (
            <img src={copyButtonNormal} className="CopyButton" onClick={copy} title="Copy Link"/>
        )
    }
}
