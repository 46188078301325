import {signOut} from "firebase/auth";
import {auth} from "../../../config/firebase";
import "./Account.css"
import {SpecialButton} from "../../generic/buttons/SpecialButton";
import {logout} from "../../auth/AuthConfig";
import {useNavigate} from "react-router-dom";
import {post} from "../../generic/api/request";

export const Account = () => {
	const navigate = useNavigate()

	const signOut = async () => {
		await logout()

		navigate("/login")
	}

	const resetPassword = async () => {
		navigate("/login/reset")
	}

	return (
		<div className="Account">
			<p>Account Email: {auth.currentUser.email} <br/></p>
			<div>
				<SpecialButton click={resetPassword}>Reset Password</SpecialButton>
				<SpecialButton click={signOut}>Log Out</SpecialButton>
			</div>
		</div>
	)
}
