import {auth, db} from "../../config/firebase"
import {Nav} from "./navigator/Navigator";
import {createRef, useEffect, useReducer, useState} from "react";
import "./Dashboard.css"
import {Display} from "./display/Display";
import {doc, getDoc, updateDoc} from "firebase/firestore";
import {useNavigate} from "react-router-dom";
import {getCookie, validateSessionCookie} from "../generic/api/request";

const DEFAULT_USERSTATE = {
    displayState: "NULL"
}

const FALLBACK_USERSTATE = {
    displayState: "Home"
}

export const Dashboard = (att) => {
    const [userState, setUserState] = useReducer((state, action) => {
        const combined = {
            ...state,
            ...action.state,
        }

        if(action.update) {
            updateDoc(doc(db, "users", auth.currentUser.uid, "client", "state"), combined)
        }

        return combined
    }, DEFAULT_USERSTATE)

    const [onDrag, setOnDrag] = useState(false)

    const navigate = useNavigate()

    const [loginCheckInterval, setLoginCheckInterval] = useState(-1)

    useEffect(() => {
        if(!auth?.currentUser?.email || !getCookie("csrf_token")) {
            navigate("/", { replace: true })
        } else {
            validateSessionCookie()
        }

        updateSettings()
    }, []);

    const onDragEnter = (e) => {
        e.preventDefault()
        setOnDrag(true)
    }

    const onDragLeave = (e) => {
        if(userState.displayState !== "Files") {
            setOnDrag(false)
        }
    }

    const updateSettings = async function () {
        try {
            let d = await getDoc(doc(db, "users", auth.currentUser.uid, "client", "state"))

            setUserState({update: false, state: d.data()})
        } catch (err) {
            setUserState({update: false, state: FALLBACK_USERSTATE})
        }
    }

    return (
        <div className="Dashboard" onDragEnter={onDragEnter} onDragLeave={onDragLeave}>
            <h1>Dashboard</h1>

            <Nav state={[userState, setUserState]}/>
            <Display state={[userState, setUserState]} onDragState={[onDrag, setOnDrag]}/>
        </div>
    )
}
