import "./Display.css"
import {Home} from "./Home";
import {Files} from "./Files";
import {Account} from "./Account";
import {useEffect, useState} from "react";
import {doc, updateDoc} from "firebase/firestore";
import {auth, db} from "../../../config/firebase";

export const Display = (att) => {
	const [userState, setUserState] = att.state

	//Files states
	const [files, setFiles] = useState(new Map())

	const [filesBeforeUpload, setFilesBeforeUpload] = useState([])
	const [filesInArgs, setFilesInArgs] = useState([])

	const [updateRunning, setUpdateRunning] = useState(false)

	const getCurrentDisplay = function (state) {
		if(state.displayState === "Files") {
			return <Files files={[files, setFiles]} filesBeforeUpload={[filesBeforeUpload, setFilesBeforeUpload]} filesInArgs={[filesInArgs, setFilesInArgs]} updateRunning={[updateRunning, setUpdateRunning]} onDragState={att.onDragState}/>
		} else if(state.displayState === "Account") {
			return <Account/>
		} else if(state.displayState === "Home") {
			return <Home/>
		}
	}

	useEffect(() => {
		window.filesApi?.on((arg) => {
			const list = []

			for(let fileData of arg) {
				const [ file ] = window.filesApi?.getFile(fileData)
				list.push(file)
			}

			if(list.length > 0) {
				setFilesInArgs(list)

				setUserState({update: true, state: {displayState: "Files"}})
			}
		})

		window.filesApi?.request()
	}, []);

	return (
		<div className="Display">
			{getCurrentDisplay(userState)}
		</div>
	)
}
