import {useEffect, useRef, useState} from "react";
import {auth} from "../../config/firebase"
import { signInWithEmailAndPassword } from "firebase/auth"
import './Auth.css'
import {LoginButton} from "../generic/buttons/LoginButton";
import {getCookie, post} from "../generic/api/request";
import {login} from "./AuthConfig";
import {Navigate, useNavigate} from "react-router-dom";

export const Auth = (obj) => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const responseRef = useRef()
    const authRef = useRef()

    const navigate = useNavigate()

    useEffect(() => {
        if(auth?.currentUser?.email && getCookie("csrf_token")) {
            navigate("/dashboard", { replace: true })
        }
        setTimeout(() => {
            if(auth?.currentUser?.email && getCookie("csrf_token")) {
                navigate("/dashboard", { replace: true })
            }
        }, 100)
    }, [])

    const signIn = async () => {
        try {
            await login(email, password)

            authRef.current.classList.add("slide-left")

            await setTimeout(() => {navigate("/dashboard", { replace: true })}, 400)
        } catch (err) {
            console.error(err)

            if(responseRef.current.classList.contains("fade")) {
                responseRef.current.classList.remove("fade")
            }

            await setTimeout(() => {
                responseRef.current.classList.add("fade")
            }, 10)
        }
    };

    return (
        <div ref={authRef} className="Auth">
            <p className="info" align="center">Sign in</p>

            <input
                align="center"
                placeholder="Username"
                onChange={(e) => setEmail(e.target.value)}
            />

            <input
                align="center"
                placeholder="Password"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
            />

            <div className="button_container">
                <LoginButton click={signIn}>Log In</LoginButton>
            </div>
            <p ref={responseRef} className="response" align="center">Wrong email or password!</p>
        </div>
    )
}
