import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore"
import { getStorage, connectStorageEmulator } from "firebase/storage"

const firebaseConfig = {
    apiKey: "AIzaSyCujXWfUvr9NewmHmS0EivvgB42InvBgm4",
    authDomain: "eagle-api-dcd66.firebaseapp.com",
    projectId: "eagle-api-dcd66",
    storageBucket: "eagle-api-dcd66.appspot.com",
    messagingSenderId: "16677622989",
    appId: "1:16677622989:web:8a3fa6fb985060915a0584",
    measurementId: "G-6F8WPXQV45"
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app)
const db = getFirestore(app)
const storage = getStorage(app)

if(process.env.NODE_ENV === "development") {
    console.log("WARNING: EMULATORS IN USE!")
    connectAuthEmulator(auth, process.env.REACT_APP_AUTH_EMULATOR);
    connectFirestoreEmulator(db, "localhost", process.env.REACT_APP_FIRESTORE_EMULATOR_PORT)
    connectStorageEmulator(storage, "localhost", process.env.REACT_APP_STORAGE_EMULATOR_PORT)
}

export {auth, db, storage}
