import {signInWithEmailAndPassword, signOut} from "firebase/auth";
import {auth} from "../../config/firebase";
import {post} from "../generic/api/request";

export const createNewSessionCookie = async (idToken) => {
    return post("/login", {
        idToken: idToken,
    })
}

export const deleteSessionCookie = async () => {
    return post("/logout")
}

export const login = async (email, password) => {
    const user = await signInWithEmailAndPassword(auth, email.trim() , password)

    const idToken = await user.user.getIdToken()
    const result = await createNewSessionCookie(idToken)

    if(result.status !== 200) {
        throw new Error("Login failed!")
    }

    return result
}

export const logout = async () => {
    try {
        await deleteSessionCookie()
    } catch (error) {
        console.error(error)
    }

    await signOut(auth)
}

export const forceLogout = async () => {
    await signOut(auth)
}
