import {getMetadata, updateMetadata, uploadBytesResumable} from "firebase/storage";
import {createRef} from "react";

export const FileState = class {
	meta = null
	uploadTask = null
	onUpload = false
	name = null
	storageRef = null
	displayRef = createRef()
	percentReady = 0.0
	files = null
	forceUpdate = null

	constructor(storageRef, files) {
		this.storageRef = storageRef
		this.files = files
	}

	async downloadMeta() {
		try {
			if(!this.onUpload) {
				this.meta = await getMetadata(this.storageRef)
				this.forceUpdate()
			}
		} catch (err) {
			console.log(err)
		}
	}

	async initMeta() {
		await updateMetadata(this.storageRef, { contentDisposition: `attachment; filename="${this.name}"` })
		await this.downloadMeta()
	}

	updateFile(newFileData) {
		if(this.onUpload) {
			return
		}

		this.onUpload = true
		this.uploadTask = uploadBytesResumable(this.storageRef, newFileData)
		this.uploadTask.pause()

		this.uploadTask.on("state_changed",
			(snapshot) => {
				this.percentReady = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
				this.uploadState = snapshot.state

				if(this.forceUpdate !== null) {
					this.forceUpdate()
				}
			},
			(error) => {
				if (this.meta !== null && this.meta !== undefined) {
					this.onUpload = false
					this.downloadMeta()
				} else {
					this.files[1](prevFiles => {
						let items = new Map()
						prevFiles.forEach((v, k) => {
							if (k !== this) {
								items.set(k, v)
							}
						})
						return items
					})
				}
			},
			() => {
				this.onUpload = false
				this.initMeta()

				let counter = 0

				const id = setInterval(() => {
					if(!this.displayRef.current?.classList.contains("onupload")) {
						setTimeout(() => {
							this.displayRef.current?.classList.add("pulse_success")
						}, 20)
						setTimeout(() => {
							this.displayRef.current?.classList.remove("pulse_success")
						}, 700)
						clearInterval(id)
					}

					if(counter > 1500) {
						clearInterval(id)
					}
					counter++
				}, 20)

				this.uploadTask = null
			}
		)

		this.uploadTask.resume()
	}
}
