import "./LoginButton.css"
import {Spinner} from "./Spinner";
import {useRef, useState} from "react";

export const LoginButton = (state) => {
	const [loading, setLoading] = useState(false)

	const onClick = async () => {
		setLoading(true)
		document.getElementsByClassName("Auth").item(0).disabled = true

		await state.click()

		document.getElementsByClassName("Auth").item(0).disabled = false
		setLoading(false)
	}

	return (
		<div className="LoginButton" align="center" onClick={onClick}>
			{loading ? <Spinner/> : <p>{state.children}</p>}
		</div>
	)
}
