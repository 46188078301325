import "./File.css"
import "./FileUpload.css"
import shareButton from "../../../../images/share_button.png"
import download_button from "../../../../images/download_button.png"
import delete_button from "../../../../images/delete_button.png"
import {deleteObject, ref, getDownloadURL} from "firebase/storage"
import {useEffect, useState} from "react";
import {FileShare} from "../fileShare/FileShare";

export const File = (e) => {
	const state = e.state
	const [files, setFiles] = e.files

	const [update, setUpdate] = useState(false)

	const [shareMode, setShareMode] = useState(false)

	useEffect(() => {
		state.forceUpdate = forceUpdate
	})

	const forceUpdate = () => {
		setUpdate(old => !old)
	}

	const shareFile = () => {
		setShareMode(true)
	}

	const deleteFile = () => {
		if(state.onUpload) {
			state.uploadTask.cancel()
		} else {
			deleteObject(ref(state.storageRef))

			state.files[1](prevFiles => {
				let items = new Map()
				prevFiles.forEach((v, k) => {
					if(k !== state) {
						items.set(k, v)
					}
				})
				return items
			})
		}
	}

	//Doesnt work with react developement server (CORS)
	const downloadFile = () => {
		getDownloadURL(state.storageRef).then(url => {
			window.open(url, "_self")
		})
	}

	const humanFileSize = function(bytes) {
		const thresh = 1000;

		if (Math.abs(bytes) < thresh) {
			return bytes + ' B';
		}

		const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
		let u = -1;
		const r = 10;

		do {
			bytes /= thresh;
			++u;
		} while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

		return bytes.toFixed(1) + ' ' + units[u];
	}

	if(state.onUpload) {
		return (
			<div className="File onupload" ref={state.displayRef}>
				<div className="file_info_div onupload"> <p className="filename_display onupload">{state.meta === undefined || state.meta === null ? state.name : state.meta.name} </p>
					<div className="process_wrapper">
						<div className="process_container">
							<div className="process" style={{width: `${state.percentReady}%`}}></div>
						</div>
						<p className="process_percent">{String(Math.round(state.percentReady * 100) / 100) + "%"}</p>
					</div>
				</div>
				<div className="file_button_div">
					<img src={delete_button} className="delete_img onupload btn" title="Cancel Upload" onClick={deleteFile}></img>
				</div>
			</div>
		)
	} else {
		return (
			<div className="File" ref={state.displayRef}>
				<div className="file_info_div"><p className="filename_display">{state.meta?.name}</p></div>
				<div className="file_button_div">
					<p className="filesize_display">{humanFileSize(state.meta?.size)}</p>
					<img src={download_button} className="download_img btn" title="Download File" onClick={downloadFile}></img>
					<img src={shareButton} className="share_img btn" title="Share File" onClick={shareFile}></img>
					<img src={delete_button} className="delete_img btn" title="Delete File" onClick={deleteFile}></img>
				</div>
				{shareMode ? <FileShare fileState={state} shareMode={[shareMode, setShareMode]}/> : ""}
			</div>
		)
	}
}
