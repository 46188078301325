import "./SpecialButton.css"
import {Spinner} from "./Spinner";
import {useEffect, useRef, useState} from "react";

export const SpecialButton = (state) => {
    const [loading, setLoading] = useState(false)
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)
    const ref = useRef()

    useEffect(() => {
        setWidth(ref.current?.clientWidth)
        setHeight(ref.current?.clientHeight)
    }, [])

    const onClick = async () => {
        if(loading) {
            return;
        }

        setLoading(true)

        await state.click()

        setLoading(false)
    }

    return (
        <div ref={ref} className={`special-btn SpecialButton${loading ? " disabled" : ""}`} onClick={onClick}>
            <div className={"special-btn_animator_wrapper"} style={{width: `${width}px`, height: `${height}px`}}>
                <div className={`special-btn_animator${loading ? " loading" : ""}`}></div>
            </div>
            <div className={`special-btn_content_wrapper${loading ? " disabled" : ""}`}>
                {state.children}
            </div>
        </div>
    )
}
