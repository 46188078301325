import {forceLogout} from "../../auth/AuthConfig";

export const getCookie = (key) => {
    const match = document.cookie.match(new RegExp("(^| )" + key + "=([^;]+)"));
    return match ? match[2] : "";
}

export const validateSessionCookie = async () => {
    return await post("/login/validate")
}

export const post = async (apiUrl, data={}) => {
    const result = await fetch((process.env.REACT_APP_API_URL && process.env.NODE_ENV === "development" ? process.env.REACT_APP_API_URL : "") + apiUrl, {
        method: "POST",
        credentials: process.env.NODE_ENV === "development" ? "include" : "same-origin",
        mode: "cors",
        body: JSON.stringify({
            ...data,
            csrf_token: getCookie("csrf_token")
        }),
        headers: {
            "Content-type": "application/json; charset=UTF-8",
        }
    })

    if(result.status === 401 && process.env.NODE_ENV === "production") {
        await forceLogout()

        window.location.reload(false);
    }

    return result
}
