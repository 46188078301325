import './App.css';
import {Auth} from "./components/auth/Auth";
import {auth} from "./config/firebase"
import {Dashboard} from "./components/dashboard/Dashboard";
import {useEffect, useState, useReducer} from "react";
import {BrowserRouter, Route, Routes, Navigate, useNavigate} from "react-router-dom";
import {IndexNavigator} from "./components/IndexNavigator";
import {Reset} from "./components/dashboard/display/account/Reset";

function App() {
    useEffect(() => {
        document.addEventListener("drop", (e) => {
            e.preventDefault();
        });
        document.addEventListener("dragstart", (e) => {
            e.preventDefault();
        });
        document.addEventListener("dragend", (e) => {
            e.preventDefault();
        });
        document.addEventListener("dragover", (e) => {
            e.preventDefault();
        });
        document.addEventListener("dragenter", (e) => {
            e.preventDefault();
        });
        document.addEventListener("dragleave", (e) => {
            e.preventDefault();
        });
    }, []);

    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<IndexNavigator/>}/>

                    <Route path="/dashboard" element={<Dashboard/>}/>
                    <Route path="/login" element={<Auth/>}/>
                    <Route path="/login/reset" element={<Reset/>}/>

                    <Route path="*" element={<Navigate to="/" replace={true} />}/>
                </Routes>
            </BrowserRouter>
        </div>
    )
}

export default App;
