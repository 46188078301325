import "./FileDropWindow.css"
import {useEffect, useRef, useState} from "react";

export const FileDropWindow = (att) => {
	const [onDrag, setOnDrag] = att.onDragState
	const [filesBeforeUpload, setFilesBeforeUpload] = att.filesBeforeUpload
	const ref = useRef()

	const getDropWindowStyle = () => {
		return {
			width: document.getElementById("root").offsetWidth,
			height: document.getElementById("root").offsetHeight,
			display: onDrag ? "flex" : "none",
		}
	}

	useEffect(() => {
		ref.current.addEventListener("drop", (e) => {
			e.preventDefault()
			att.uploadFiles(e.dataTransfer?.files)
			setOnDrag(false)
		})
		ref.current.addEventListener("dragleave", (e) => {
			e.preventDefault()
			setOnDrag(false)
		})
	}, [])

	return (
		<div ref={ref} className="FileDropWindow" style={getDropWindowStyle()}>
			<div className="drop_target">
				<div className="drop_target_style">
					<h2 className="drop_target_headline">Upload to server</h2>
					<p className="drop_target_info">Drop Files to upload them to the server!</p>
				</div>
			</div>
		</div>
	)
}
