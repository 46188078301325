import "./FileShare.css"
import {useEffect, useRef, useState} from "react";
import {CopyButton} from "../../../generic/buttons/CopyButton";
import {Spinner} from "../../../generic/buttons/Spinner";
import {post} from "../../../generic/api/request";
import {doc, getDoc} from "firebase/firestore";
import {auth, db} from "../../../../config/firebase";
import {useReducer} from "react";

export const FileShare = (e) => {
    const fileState = e.fileState
    const [shareMode, setShareMode] = e.shareMode

    const [shareObj, setShareObj] = useState()

    const [foundShare, setFoundShare] = useState(false)
    const [spinner, setSpinner] = useState(true)

    const inputRef = useRef()

    const [timer, setTimer] = useReducer((state, action) => {
        if(!foundShare) {
            return "00:00"
        }

        const time = action.shareObj.expirationDate - new Date().getTime()

        let seconds = Math.floor(time / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);

        seconds = seconds % 60;
        minutes = minutes % 60;

        const newState = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`

        if(time <= 0) {
            setFoundShare(false)
            clearInterval(intervalId)
        }

        return newState
    }, "00:00")

    const [intervalId, setIntervalId] = useState(-1)

    const closeShare = () => {
        setShareMode(false)
        clearInterval(intervalId)
    }

    const getShare = async (create=true) => {
        const res = await post("/share", {
            fileRef: fileState.storageRef.fullPath,
            autoCreateNew: create,
        })

        if(!res || res.status !== 200) {
            closeShare()
            return {exists: false}
        }

        return await res.json()
    }

    const revokeShare = async () => {
        if(foundShare && shareObj) {
            setSpinner(true)
            await post("/share/revoke", shareObj)

            setFoundShare(false)
            setSpinner(false)
        }
    }

    const copyShareLink = () => {
        inputRef.current.select()
        navigator.clipboard.writeText(shareObj.downloadUrl)
    }

    const init = async (e, preLoad) => {
        setSpinner(true)
        setFoundShare(false)

        const shareObj = preLoad ? preLoad : await getShare()

        setShareObj(shareObj)
        setTimer({shareObj: shareObj})

        setIntervalId(prevInterval => {
            if(prevInterval !== -1) {
                clearInterval(prevInterval)
            }

            return setInterval(() => {
                setShareObj(prevShare => {
                    setTimer({shareObj: prevShare})
                    return prevShare
                })
            }, 1000)
        })

        setFoundShare(true)
        setSpinner(false)
    }

    const display = () => {
        if (spinner) {
            return (
                <div className="spinner_share">
                    <Spinner/>
                </div>
            )
        } else if (!foundShare) {
            return (
                <div className="create_share" onClick={init}>
                    <p className="create_share_plus">+</p>
                </div>
            )
        } else if (foundShare) {
            return (
                <div className="actual_share">
                    <div className="share_timer_wrapper">
                        <p className="share_timer">{timer}</p>
                    </div>
                    <div className="share_link_wrapper">
                        <input ref={inputRef} className="share_link" value={shareObj?.downloadUrl} readOnly={true} onClick={e => {e.target.select()}}/>
                        <CopyButton onClick={copyShareLink}/>
                    </div>
                    <div className="share_revoke_button_wrapper">
                        <button className="share_revoke_button" onClick={revokeShare}>Revoke</button>
                    </div>
                </div>
            )
        }
    }

    useEffect(() => {
        getShare(false).then(share => {
            if(share.exists) {
                init(share)
            } else {
                setSpinner(false)
            }
        })
    }, [])

    return (
        <div className="FileShare">
            <div className="share_info">
                <div className="share_headline"><p>Share of {fileState.meta?.name}</p></div>
                <div className="share_close" onClick={closeShare}><p>X</p></div>
                {display()}
            </div>
        </div>
    )

}
